<template>
  <v-container fluid>
    <head-title text="Movimientos de Cuenta" :to="{ name: 'Home' }"></head-title>
    <v-row>
      <v-col cols="12" md="6">
        <company-field
          v-model="companyId"
          @value="(val) => (companyId = val)"
        ></company-field>
      </v-col>
      <v-col cols="12" md="6">
        <account-field
          v-model="accountId"
          :companyId="companyId"
          :disabled="disableFilter"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          :items="[
            { text: 'Por Mes', value: 0 },
            { text: 'Por Fecha', value: 1 },
            { text: 'Por Período', value: 2 },
          ]"
          label="Mostrar Datos"
          v-model="searchType"
          :readonly="disableFilter"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" v-if="searchType === 1">
        <date-field
          label="Fecha"
          v-model="date"
          :disabled="disableFilter"
          :reset="disableFilter"
        ></date-field>
      </v-col>
      <v-col cols="12" md="3" v-else-if="searchType === 0">
        <date-field
          label="Mes"
          v-model="date"
          type="month"
          :disabled="disableFilter"
          :reset="disableFilter"
        ></date-field>
      </v-col>
      <v-col cols="12" md="6" v-else-if="searchType === 2">
        <v-row>
          <v-col cols="12" md="6">
            <date-field
              label="De"
              v-model="date"
              :disabled="disableFilter"
              :reset="disableFilter"
            ></date-field>
          </v-col>
          <v-col cols="12" md="6">
            <date-field
              label="Hasta"
              v-model="until"
              :disabled="disableFilter"
              :reset="disableFilter"
            ></date-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <v-btn
          class="mx-1"
          fab
          :dark="!disableFilter"
          small
          color="indigo"
          @click="search()"
          :disabled="disableFilter"
        >
          <v-icon dark>mdi-database-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="1" class="text-left">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                :dark="!disableFilter"
                small
                color="indigo"
                v-on="on"
                v-bind="attrs"
                :disabled="disableFilter"
              >
                <v-icon>mdi-plus</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item @click="newCredit()" link>
                <v-list-item-title>Nuevo Crédito</v-list-item-title>
              </v-list-item>
              <v-list-item @click="newDebit()" link>
                <v-list-item-title>Nuevo Débito</v-list-item-title>
              </v-list-item>
              <v-list-item @click="newTransfer()" link>
                <v-list-item-title>Nueva Transferencia</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" md="2" class="text_right">
        <v-switch
          color="indigo"
          class="mt-1"
          v-model="floating"
          label="Cheques en circulación"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" v-if="searchType === 0 && status">
        <v-switch
          label="ORDENAR MANUALMENTE"
          v-model="order"
          :disable="disableFilter"
        ></v-switch>
      </v-col>
      <v-col cols="12" md="2" class="pt-7" v-if="showStatus">
        <v-btn @click="openStatus()" :disable="disableFilter">
          <span>RESUMEN</span>
          <v-icon>mdi-content-save-check</v-icon>
        </v-btn>
      </v-col>
      <v-divider></v-divider>
      <br />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="filter"
      :disable-sort="disableSort"
      hide-default-footer
      disable-pagination
      class="elevation-1"
    >
      <template v-slot:top v-if="items.length > 0">
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:[`item.month_ordering`]="{ item }">
        <!-- <v-form @submit="updateOrder"> -->
        <v-text-field
          v-if="status"
          type="number"
          v-model="item.month_ordering"
          @keyup.13="updateOrder(item.id, item.month_ordering, $event)"
          ref="textFieldOrder"
          :rules="valueRules"
          :disabled="!status"
        ></v-text-field>
        <span v-else>{{ item.month_ordering }}</span>
        <!-- </v-form> -->
      </template>
      <template v-slot:[`item.is_confirmed`]="{ item }">
        <v-icon
          v-if="!item.is_confirmed"
          @click="edit(item)"
          medium
          color="blue lighten-1"
          class="pr-4"
          >mdi-file-document-edit-outline</v-icon
        >
        <v-icon
          v-if="!item.is_confirmed"
          medium
          @click="destroy(item)"
          color="red lighten-1"
          class="pr-4"
          >mdi-trash-can-outline</v-icon
        >
        <v-icon v-if="item.is_confirmed" color="success">mdi-check-all</v-icon>
        <v-icon
          v-show="hasPermission('_view_accountings_unconfirmedinvoice')"
          v-if="item.is_confirmed && status"
          color="warning"
          @click="unconfirmed(item)"
          >mdi-text-box-edit</v-icon
        >
        <v-icon
          v-show="hasPermission('_view_accountings_unconfirmedinvoice')"
          v-else-if="item.is_confirmed && floating"
          color="warning"
          @click="confirmMarkCheckAsCleared(item)"
          >mdi-progress-check</v-icon
        >
        <!-- <v-icon v-else @click="confirmed(item)" medium color="blue lighten-1"
          >mdi-check-underline-circle</v-icon
        > -->
      </template>
      <template v-slot:[`item._bank`]="{ item }">
        <span v-if="item._bank != null">{{ item._bank }}-{{ item._account }}</span>
        <span v-else></span>
      </template>
      <template v-slot:[`item.movement_date`]="{ item }">
        {{ item.movement_date | datef }}
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <span v-if="item.code == '' && item.code == null"></span>
        <span v-else>{{ item.code }}</span>
      </template>
      <template v-slot:[`item.cre`]="{ item }">
        <span v-if="item.cre != null">
          {{ item.cre | currency }}
        </span>
        <span v-else>
          {{ item.cre }}
        </span>
      </template>
      <template v-slot:[`item.deb`]="{ item }">
        <span v-if="item.deb != null">{{ item.deb | currency }}</span>
        <span v-else>{{ item.deb }}</span>
      </template>
      <template v-slot:[`item.balance`]="{ item }">
        <span v-if="item.balance != null"> {{ item.balance | currency }}</span>
        <span v-else>{{ item.balance | currency }}</span>
      </template>
    </v-data-table>
    <edit-movement
      v-if="movementInId != 0"
      :updateDialog="updateDialogMovement"
      :movementInId="movementInId"
      @close="(updateDialog = false), (movementInId = 0)"
      @updated="floating ? getFloatingChecks() : search()"
    ></edit-movement>
    <!-- account status -->
    <status-field
      v-if="statusDialog"
      :dialog="statusDialog"
      :year="yearStatus"
      :month="monthStatus"
      :account="accountStatus"
      @close="closeStatusDialog()"
    ></status-field>
    <new-credit :dialog="creditModal" @close="creditModal = false"></new-credit>
    <new-debit :dialog="debitModal" @close="debitModal = false"></new-debit>
    <new-transfer :dialogt="transferModal" @close="transferModal = false"></new-transfer>
    <!-- destroy modal -->
    <v-dialog v-model="dialogDestroy" persistent max-width="500" v-if="dialogDestroy">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> ¿Eliminar Movimiento Cuenta? </v-card-title>
        <v-card-text
          >Si continua el movimiento con el documento {{ itemDestroy.check_code }}
          {{ itemDestroy.code }} será eliminado.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogDestroy = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="updateMvn('destroy')">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm mdal -->
    <v-dialog v-model="dialogUnconfirm" persistent max-width="500" v-if="dialogUnconfirm">
      <v-card>
        <v-card-title class="text-h5"> ¿Habilitar Movimiento de Cuenta? </v-card-title>
        <v-card-text
          >Si continua el movimiento con el documento {{ itemDestroy.check_code }}
          {{ itemDestroy.code }} se habilitará para ser editado o eliminado</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogUnconfirm = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="updateMvn('unconfirmed')">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm mdal to mark check as Cleared -->
    <v-dialog
      v-model="dialogToMarkCheck"
      persistent
      max-width="500"
      v-if="dialogToMarkCheck"
    >
      <v-card>
        <v-card-title class="text-h5">
          ¿Marcar el cheque {{ itemDestroy.check_code }} como liquidado?
        </v-card-title>
        <v-card-text>
          <p>Para continuar debe ingresar la fecha de cobro</p>
          <v-text-field
            v-model="clearingDate"
            label="Fecha de cobro"
            type="date"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(dialogToMarkCheck = false), (clearingDate = undefined)"
          >
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="updateMvn('setclearingdate')">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    CompanyField: () => import("@/components/fields/CompanyField"),
    AccountField: () => import("@/components/fields/AccountField"),
    StatusField: () => import("@/components/accounts/AccountStatus"),
    DateField: () => import("@/components/fields/DateField.vue"),
    EditMovement: () => import("@/components/accounts/EditMovementIn"),
    NewCredit: () => import("@/components/accounts/Credit.vue"),
    NewDebit: () => import("@/components/accounts/Debit.vue"),
    NewTransfer: () => import("@/components/accounts/Transfer.vue"),
  },
  data: () => ({
    // accout status
    disableFilter: false,
    showStatus: false,
    status: false,
    creditModal: false,
    debitModal: false,
    transferModal: false,
    statusDialog: false,
    yearStatus: "",
    monthStatus: "",
    accountStatus: 0,
    paramOrder: null,
    order: false,
    disableSort: false,
    filter: "",
    enable: false,
    //
    movementInId: 0,
    updateDialogMovement: false,
    companyId: 0,
    accountId: "",
    date: "",
    until: "",
    year: null,
    month: null,
    searchType: null,
    //
    items: [],
    loading: false,
    headers: [
      { text: "Fecha", value: "movement_date" },
      { text: "Boleta", value: "code" },
      { text: "Cheque", value: "check_code" },
      { text: "Descripción", value: "description" },
      { text: "Tipo", value: "_reason" },
      { text: "TT", value: "_tt" },
      { text: "Documento", value: "document" },
      { text: "Crédito", value: "cre", align: "end" },
      { text: "Débito", value: "deb", align: "end" },
      { text: "Creado", value: "created_by" },
      { text: "Confirmado", value: "_confirmed_by" },
      { text: "Estado", value: "is_confirmed", align: "end", width: "140" },
    ],
    dialogDestroy: false,
    dialogUnconfirm: false,
    dialogToMarkCheck: false,
    itemDestroy: {},
    data: {},
    permissions: [],
    valueRules: [rules.required, rules.minValue(0.1)],
    floating: false,
    clearingDate: undefined,
  }),
  mounted() {
    this.searchType = 0;
    this.permissions = this.$store.state.auth.user.permissions;
  },
  methods: {
    hasPermission(permission) {
      return this.permissions.includes(permission) || permission === "any" ? true : false;
    },
    updateOrder(id, order, e) {
      requests
        .patch("/api/accounts/updateOrder/" + id, {
          month_ordering: order,
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.enable = true;
            this.search(true);
            this.$toasted.global.info({
              message: "Operación realizada con éxito",
            });
            e.target.blur();
          } else {
            this.$toasted.global.error({
              message: resp.data.detail,
            });
            console.log(resp);
          }
        });
    },
    getList(uri) {
      this.loading = true;
      requests.get(uri).then((response) => {
        if (response.status == 200) {
          this.items = response.data;
          if (this.searchType === 0) {
            requests
              .get(
                "api/accounting/account-status/?account=" +
                  this.accountId +
                  "&year=" +
                  this.date.substring(0, 4) +
                  "&month=" +
                  this.date.substring(5, 7)
              )
              .then((res) => {
                if (res.status == 200) {
                  this.status = res.data.results[0].editable;
                  this.showBalance()
                  this.showStatus = true;
                } else {
                  this.$toasted.global.info({
                    message: res.data.detail,
                  });
                  this.close();
                }
              });
          }
        }
        this.loading = false;
      });
    },
    destroy(item) {
      this.itemDestroy = item;
      this.dialogDestroy = true;
    },
    updateMvn(action) {
      if (action == "destroy") {
        if (this.itemDestroy != undefined) {
          requests
            .delete("api/accounts/movements-in/" + this.itemDestroy.id + "/")
            .then((res) => {
              if (res.status == 204) {
                this.dialogDestroy = false;
                this.search();
              } else {
                if (res.data.detail != undefined) {
                  this.$toasted.global.info({
                    message: res.data.detail,
                  });
                  this.valid = true;
                }
              }
            });
        }
      } else if (action == "unconfirmed" || action == "setclearingdate") {
        let data = {};
        // validar si requiere desconfirmar o ingresar la fecha de cobro
        if (action == "setclearingdate") {
          if (this.clearingDate != undefined) {
            data = { clearing_date: this.clearingDate };
          } else {
            return this.$toasted.global.info({
              message: "Debe ingresar la fecha de cobro",
            });
          }
        } else {
          data = { is_confirmed: false };
        }
        requests
          .patch("api/accounts/movements-in/" + this.itemDestroy.id + "/", data)
          .then((res) => {
            if (res.status == 200) {
              this.dialogUnconfirm = false;
              this.dialogToMarkCheck = false;
              action == "setclearingdate" ? this.getFloatingChecks() : this.search();
            } else {
              if (res.data.detail != undefined) {
                this.$toasted.global.info({
                  message: res.data.detail,
                });
                this.valid = true;
              }
            }
          });
      }
    },
    unconfirmed(item) {
      this.itemDestroy = item;
      this.dialogUnconfirm = true;
    },
    search(enableOrder = false) {
      !enableOrder ? (this.enable = false) : (this.enable = true);

      var uri = "";
      if (this.accountId.length !== 36) {
        this.$toasted.global.info({
          message: "Seleccione una cuenta",
        });
        return;
      }
      if (this.date === null) {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
        return;
      }
      if (this.date === undefined) {
        this.$toasted.global.info({
          message: "Seleccione la fecha",
        });
        return;
      }
      if (this.searchType === 1) {
        uri = "api/accounts/movements/?date=" + this.date + "&account=" + this.accountId;
      } else if (this.searchType === 0) {
        uri =
          "api/accounts/movements/?year=" +
          this.date.substring(0, 4) +
          "&month=" +
          this.date.substring(5, 7) +
          "&account=" +
          this.accountId;
        if (this.order) {
          uri += "&order=" + this.paramOrder;
        }
      } else if (this.searchType === 2) {
        if (this.until === null) {
          this.$toasted.global.info({
            message: "Seleccione la fecha",
          });
          return;
        } else {
          uri =
            "api/accounts/movements/?date=" +
            this.date +
            "&until=" +
            this.until +
            "&account=" +
            this.accountId;
        }
      }
      this.getList(uri);
    },
    edit(item) {
      this.updateDialogMovement = true;
      this.movementInId = item.id;
    },
    openStatus() {
      if (this.accountStatus.length != 36 || this.searchType != 0 || this.data == null) {
        return this.$toasted.global.info({
          message: "Debe seleccionar No. de cuenta bancaria, y el mes",
        });
      } else {
        this.statusDialog = true;
        this.yearStatus = this.date.substring(0, 4);
        this.monthStatus = this.date.substring(5, 7);
      }
    },
    closeStatusDialog() {
      this.statusDialog = false;
      this.yearStatus = undefined;
      this.monthStatus = undefined;
      this.search();
    },
    newCredit() {
      this.creditModal = true;
    },
    newDebit() {
      this.debitModal = true;
    },
    newTransfer() {
      this.transferModal = true;
    },
    getFloatingChecks() {
      if (this.companyId != undefined && this.companyId > 0) {
        this.loading = true;
        requests
          .get("/api/accounts/movements/?floating=true&company=" + this.companyId)
          .then((res) => {
            if (res.status == 200) {
              this.status = false;
              this.floating = true;
              this.items = res.data;
            } else if (res.status > 400) {
              alert(res.data);
            }
          });
        this.loading = false;
      } else {
        this.$toasted.global.info({
          message: "Seleccione una sucursal",
        });
        return;
      }
    },
    confirmMarkCheckAsCleared(item) {
      this.itemDestroy = item;
      this.dialogToMarkCheck = true;
    },
    showBalance() {
      if (!this.status) {
        if(this.headers[9].value != "balance"){
          this.headers.splice(9, 0, { text: "Saldo", value: "balance" });
        }
      } else {
        if(this.headers[9].value == "balance"){
          this.headers.splice(9, 1) 
        } 
      }
    },
  },
  watch: {
    floating(val) {
      if (val) {
        this.disableFilter = true;
        this.getFloatingChecks();
        // disable filters and buttons
        this.headers.splice(1, 1);
        this.headers.splice(6, 1);
        !this.status && this.headers[7].value == "balance"
          ? this.headers.splice(7, 1)
          : "";
        // add new headers
        this.headers.splice(1, 0, {
          text: "Cuenta",
          align: "start",
          sortable: true,
          value: "_bank",
        });
      } else {
        this.items = [];
        // enable filters and buttons
        this.disableFilter = false;
        this.headers.splice(1, 1);
        // add new headers column 1 and 7
        this.headers.splice(1, 0, {
          text: "Boleta",
          align: "start",
          sortable: true,
          value: "code",
        });
        this.headers.splice(7, 0, {
          text: "Crédito",
          align: "end",
          sortable: false,
          value: "cre",
        });
      }
    },
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
      this.order = false;
      this.accountId = "";
      this.accountStatus = 0;
      if (this.floating && this.companyId > 0) {
        this.getFloatingChecks();
      }
    },
    searchType(newValue) {
      if (newValue === 1) {
        this.date = new Date().toISOString().substring(0, 10);
      }
      if (newValue === 0) {
        this.date = new Date().toISOString().substring(0, 7);
      }
      if (newValue === 2) {
        this.date = new Date().toISOString().substring(0, 10);
        this.until = new Date().toISOString().substring(0, 10);
      }
      this.order = false;
    },
    order(val) {
      if (val) {
        this.headers.splice(1, 0, {
          text: "Orden",
          value: "month_ordering",
          width: "90",
        });
        this.headers.splice(10, 0, { text: "Saldo", value: "balance" });
        this.enable = true;
        this.search(true);
      } else {
        this.headers.splice(1, 1);
        this.headers.splice(9, 1);
        this.enable = false;
        this.search(false);
      }
    },
    enable(val) {
      if (!val) {
        this.disableSort = false;
        this.order = false;
        this.paramOrder = null;
      } else {
        this.disableSort = true;
        this.paramOrder = true;
      }
    },
    dialogDestroy(val) {
      if (!val) {
        this.itemDestroy = undefined;
      }
    },
    accountId(val) {
      this.order = false;
      if (val.length == 36) {
        this.accountStatus = val;
      } else {
        this.accountStatus = 0;
      }
    },
    date() {
      this.order = false;
    },
  },
};
</script>
